<div class="color-selector" [formGroup]="parentFormGroup">
    <label *ngFor="let color of colors" class="color-option">
        <input type="radio" name="color" [value]="color.id" formControlName="color" />
        <div class="color-block" [style.background-color]="color.background">
            <div class="color-block-selected-indicator">
                <esc-icon icon="check_circle" large fill />
            </div>
        </div>
    </label>
</div>
