import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IGroupColor } from '../../../../core/models/group.interface';
import { NgFor } from '@angular/common';
import { IconComponent } from '@early-stage/components';

@Component({
    selector: 'es-color-selector',
    templateUrl: './color-selector.component.html',
    styleUrls: ['./color-selector.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgFor, IconComponent],
})
export class ColorSelectorComponent {
    @Input() public colors: IGroupColor[];
    @Input() public parentFormGroup: FormGroup<{ color: FormControl<number> }>;
}
